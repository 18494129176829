import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: function () {
      return import( /* webpackChunkName: "pages-home" */ '../views/pages/Home.vue')
    }
  },
  {
    path: '/crm',
    name: 'Crm',
    component: function () {
      return import( /* webpackChunkName: "pages-relatonships" */ '../views/pages/Crm.vue')
    }
  },
  {
    path: '/modsnip',
    name: 'ModSnip',
    component: function () {
      return import( /* webpackChunkName: "pages-modsnip" */ '../views/pages/ModSnip.vue')
    }
  },

  {
    path: '/websites',
    name: 'Websites',
    component: function () {
      return import( /* webpackChunkName: "pages-websites" */ '../views/pages/Websites.vue')
    }
  },

  {
    path: '/cookies',
    name: 'Cookies',
    component: function () {
      return import( /* webpackChunkName: "pages-cookies" */ '../views/pages/Cookies.vue')
    }
  },
  {
    path: '/why-friday',
    name: 'WhyFriday',
    component: function () {
      return import( /* webpackChunkName: "pages-why-friday" */ '../views/pages/WhyFriday.vue')
    }
  },
  {
    path: '/our-collective',
    name: 'OurCollective',
    component: function () {
      return import( /* webpackChunkName: "pages-our-collective" */ '../views/pages/OurCollective.vue')
    }
  },
  {
    path: '/startups',
    name: 'Startups',
    component: function () {
      return import( /* webpackChunkName: "pages-startups" */ '../views/pages/Startups.vue')
    }
  },
  {
    path: '/send-smoke-signal',
    name: 'SendSmokeSignal',
    component: function () {
      return import( /* webpackChunkName: "pages-send-smoke-signal" */ '../views/pages/SendSmokeSignal.vue')
    }
  },
  {
    path: '/join-the-collective',
    name: 'SendSmokeSignal',
    component: function () {
      return import( /* webpackChunkName: "pages-join-the-collective" */ '../views/pages/JoinTheCollective.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router