<template>
  <div class="fixed top-0 w-screen">
    <nav ref="mainNav" class="flex flex-row justify-between p-3">
      <div class="my-auto">
        <router-link class="navbar-brand" to="/">
          <!-- <img
            src="@/assets/images/logo/coloured.png"
            alt="Friday Collective"
            width="200"
          /> -->

          <h1 class="text-3xl font-extrabold text-black">
            friday<span class="fc-text-pink">collective</span>
          </h1>
        </router-link>
      </div>

      <div class="my-auto">
        <!-- <div
          class="cursor-pointer"
          :class="$route.path == '/' ? 'text-white' : 'text-black'"
          @click="$store.navOpen = !$store.navOpen"
        >
          <i class="fad fa-circle-notch text-purple fa-2x"></i>
        </div> -->
      </div>
    </nav>

    <div
      class="h-screen w-screen fixed top-0 left-0 bg-white"
      v-if="$store.navOpen"
    >
      <div class="flex flex-row justify-between p-3 mb-4">
        <div class="my-auto">
          <img
            src="@/assets/images/logo/coloured.png"
            alt="Friday Collective"
            width="200"
          />
        </div>
        <div class="my-auto">
          <i
            class="far fa-times fa-2x text-purple cursor-pointer"
            @click="$store.navOpen = !$store.navOpen"
          ></i>
        </div>
      </div>
      <div class="">
        <div class="flex flex-col items-center">
          <h2
            v-if="$route.path != '/'"
            @click="navigateTo('/')"
            class="
              cursor-pointer
              mb-8
              text-2xl
              md:text-4xl
              font-bold
              text-purple
            "
          >
            Home
          </h2>

          <h2
            @click="navigateTo('/custom-software')"
            class="
              cursor-pointer
              mb-8
              text-2xl
              md:text-4xl
              font-bold
              text-purple
            "
          >
            CUSTOM SOFTWARE
          </h2>

          <h2
            @click="navigateTo('/web-apps')"
            class="
              cursor-pointer
              mb-8
              text-2xl
              md:text-4xl
              font-bold
              text-purple
            "
          >
            WEB APPS
          </h2>
          <h2
            @click="navigateTo('/crm')"
            class="
              cursor-pointer
              mb-8
              text-2xl
              md:text-4xl
              font-bold
              text-purple
            "
          >
            CRM &amp; DASHBOARDS
          </h2>
          <h2
            @click="navigateTo('/vr-experiences')"
            class="
              cursor-pointer
              mb-8
              text-2xl
              md:text-4xl
              font-bold
              text-purple
            "
          >
            VR &amp; AR EXPERIENCES
          </h2>

          <h2
            @click="navigateTo('/mobile-apps')"
            class="
              cursor-pointer
              mb-8
              text-2xl
              md:text-4xl
              font-bold
              text-purple
            "
          >
            MOBILE APPS
          </h2>

          <h2
            @click="navigateTo('/contact')"
            class="
              cursor-pointer
              mb-8
              text-2xl
              md:text-4xl
              font-bold
              text-purple
            "
          >
            CONTACT US
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    "$store.navOpen": {
      handler: function (val, oldVal) {
        if (val) {
          document.querySelector("body").style = "position:fixed";
        } else {
          document.querySelector("body").style = "";
        }
      },
    },
  },
  methods: {
    openNav() {
      this.$store.navOpen = true;
    },
    navigateTo(path) {
      this.$store.navOpen = false;
      this.$router.push(path);
    },
    handleScroll(event) {
      if (window.scrollY > window.innerHeight / 5) {
        this.$refs.mainNav.classList.add("gradient-nav");
      } else {
        this.$refs.mainNav.classList.remove("gradient-nav");
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    console.log(this.$route.path);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.gradient-nav {
  background: rgba(255, 255, 255, 0.75);
}

.nav-link {
  color: #000;
}

.nav-link:hover {
  color: rgb(85, 85, 85);
}

.nav-link-light {
  color: #fff;
  text-decoration: none;
}

.nav-link-light:hover {
  color: rgb(237, 237, 237);
}

/* .navbar-collapse {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.25);
} */

.full-screen-nav {
  z-index: 9999;
  overflow: -moz-hidden-unscrollable;
}
</style>
