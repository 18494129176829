<template>
  <section class="home-footer bg-black text-white vh-100">
    <div class="d-flex h-100 justify-content-center align-items-center">
      <div class="w-100">
        <div class="container">
          <h4 class="light-gray mb-5">Who doesn't lke Fridays?</h4>

          <div class="row">
            <div class="col-md-4 mb-4 mb-md-0">
              <ul class="list-unstyled">
                <li
                  class="mb-3 cursor-pointer"
                  @click="navigateTo('/our-collective')"
                >
                  Our Collective
                </li>
                <li
                  class="mb-3 cursor-pointer"
                  @click="navigateTo('/join-the-collective')"
                >
                  Join the Collective
                </li>
              </ul>
            </div>
            <div class="col-md-4 mb-4 mb-md-0">
              <ul class="list-unstyled">
                <li
                  class="mb-3 cursor-pointer"
                  @click="navigateTo('/startups')"
                >
                  Startup Packages
                </li>

                <li
                  class="mb-3 cursor-pointer"
                  @click="navigateTo('/websites')"
                >
                  Websites
                </li>
                <li class="mb-3 cursor-pointer" @click="navigateTo('/apps')">
                  Apps
                </li>
                <li class="mb-3 cursor-pointer" @click="navigateTo('/crm')">
                  Client / Customer Relationship Management
                </li>
              </ul>
            </div>
            <div class="col-md-4 mb-4 mb-md-0">
              <div class="mb-4">
                <button class="btn btn-dark moving-gradient-button btn-sm">
                  Customer Login
                </button>
              </div>
              <div>
                <button class="btn btn-outline-light btn-sm">
                  Staff Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style>
</style>