import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import 'bootstrap';
import "@/assets/styles/app.scss";

Vue.config.productionTip = false


import AOS from 'aos'
import 'aos/dist/aos.css'
import VueStash from 'vue-stash';
import store from './store'
Vue.use(VueStash)

import axios from "axios"
Vue.prototype.$axios = axios

Vue.prototype.$axios.defaults.headers.common['Content-Type'] = 'application/json'
Vue.prototype.$axios.defaults.headers.common['Accept'] = 'application/json'




new Vue({
  created() {
    AOS.init()
  },
  data: {
    store,
  },
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')