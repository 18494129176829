<template>
  <div id="app">
    <div
      class="p-2 position-fixed w-100 bottom-0 moving-gradient"
      style="z-index: 999"
      v-if="updateExists"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col my-auto">
            <p class="text-white mb-0">
              Important: There is an update available for the Friday Collective!
              To receive the latest updates please reload the page or click the
              button
            </p>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button
              @click="refreshApp"
              class="btn btn-sm btn-outline-light fw-bold"
            >
              <i class="far fa-redo-alt me-2"></i>Click to update
            </button>
          </div>
        </div>
      </div>
    </div>
    <main-nav></main-nav>
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
    <cookie-law theme="hot-pink">
      <div slot="message" class="fw-bold">
        This site uses cookies from Friday Collective to deliver its services
        and to analyse traffic.
        <router-link to="/cookies">Learn more</router-link>
      </div>
    </cookie-law>
    <!-- <main-footer></main-footer> -->
  </div>
</template>
<script>
import CookieLaw from "vue-cookie-law";
import MainNav from "./views/layouts/MainNav";
import MainFooter from "./views/layouts/Footer";

export default {
  metaInfo: {
    title: "Friday Collective",
    titleTemplate: "%s | CRM, Apps, Websites, Digital Strategy",
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
  created() {
    // Checking for service worker updates
    document.addEventListener("applicationUpdated", this.showRefreshUI, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  components: {
    MainNav,
    MainFooter,
    CookieLaw,
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100vh;
}

.Cookie--hot-pink {
  background: linear-gradient(-45deg, #6c4a9e, #c04c9c, #df095b, #f00d76);
  color: #fff;
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;

  padding: 1.25em;
}
.Cookie--hot-pink .Cookie__button {
  background: #fff;
  color: #000;
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  padding: 0.625em 3.125em;
  border-radius: 0;
  border: 0;
  font-size: 1em;
}
.Cookie--hot-pink div.Cookie__button:hover {
  color: #333 !important;
  font-weight: bold !important;
}

.Cookie--hot-pink a {
  color: #fff;
}
</style>
